import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
const KroggerView = () => import('../views/KroggerView.vue');
const DuumbaRuumbaView = () => import('../views/DuumbaRuumbaView.vue');
const OurDyingRightsView = () => import('../views/OurDyingRightsView.vue');
const BomberbotsView = () => import('../views/BomberbotsView.vue');
const BugShooterView = () => import('../views/BugShooterView.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Portfolio',
    component: Home,
  },
  {
    path: '/portfolio/krogger',
    name: 'Krogger',
    component: KroggerView,
  },
  {
    path: '/portfolio/duumba-ruumba',
    name: 'DUUMBA RUUMBA',
    component: DuumbaRuumbaView,
  },
  {
    path: '/portfolio/our-dying-rights',
    name: 'Our Dying Rights',
    component: OurDyingRightsView,
  },
  {
    path: '/portfolio/bomberbots',
    name: 'Bomberbots',
    component: BomberbotsView,
  },
  {
    path: '/portfolio/bug-shooter',
    name: 'Bug Shooter',
    component: BugShooterView,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes,
});

declare global {
  interface Document {
    startViewTransition?: (callback: () => void) => void;
  }
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
  }
}

// Add ViewTransition logic
router.beforeEach((to, from, next) => {
  if (document.startViewTransition) {
    // Use the ViewTransition API for route changes
    document.startViewTransition(() => {
      next(); // Proceed with the navigation
    });
  } else {
    next(); // Fallback for unsupported browsers
  }
});

router.afterEach((to) => {
  if (to.path !== '/' && window.gtag) {
    window.gtag('event', 'screen_view', {
      screen_name: to.name,
    });
  }
});

export default router;
